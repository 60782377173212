<template>
  <label class="form-check-label ms-3 bg-highlight p-2 shadow-s rounded-s">{{
    skill.name
  }}</label>
</template>

<script>
export default {
  props: {
    skill: Object,
  },
  methods: {
    deleteData(id) {
      this.$emit("deleteData", id);
    },
  },
};
</script>
