<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <Footer logged />
    <!-- Page Title-->
    <div class="page-title page-title-fixed">
      <a
        @click="$router.go(-1)"
        class="page-title-icon rounded-s ms-3 bg-theme color-theme"
        ><i class="fas fa-chevron-left"></i
      ></a>
      <h1 class="mx-auto font-18">Skill</h1>
      <a data-menu="menu-main" class="page-title-icon rounded-s ms-3"></a>
    </div>
    <div class="page-title-clear"></div>

    <!-- End Page Title-->

    <!-- Experience List-->
    <div class="page-content mt-4">
      <!-- Search Skill -->
      <div class="" data-card-height="250">
        <div class="text-center">
          <div class="content">
            <div class="example">
              <Multiselect
                v-model="skill"
                placeholder="Temukan skill anda"
                :filter-results="false"
                :min-chars="1"
                :resolve-on-load="false"
                :delay="0"
                :searchable="true"
                :options="
                  async function (query) {
                    return await fetchSkill(query);
                  }
                "
              />
            </div>
          </div>
        </div>
      </div>
      <!-- End Search Skill -->
      <!-- Skill List -->
      <Skill
        v-for="skill in listSkills"
        :key="skill.id"
        :skill="skill"
        @deleteData="deleteData"
      />
      <!-- Skill List -->
    </div>
    <!-- End Experience List-->

    <!-- Main Menu-->
    <div
      id="menu-main"
      class="menu menu-box-left rounded-0"
      data-menu-width="280"
      data-menu-active="nav-welcome"
    >
      <MenuMain @close="menuClose" />
    </div>

    <!-- Share Menu-->
    <div
      id="menu-share"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-share.html"
      data-menu-height="370"
    ></div>

    <!-- Colors Menu-->
    <div
      id="menu-colors"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-colors.html"
      data-menu-height="480"
    >
      <MenuColors @close="menuClose" />
    </div>
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import MenuMain from "@/components/menu/MenuMain";
import MenuColors from "@/components/menu/MenuColors";
import Multiselect from "@vueform/multiselect";
import Skill from "@/components/job_seeker/Skill.vue";
export default {
  name: "UserSkill",
  components: {
    Header,
    Footer,
    MenuMain,
    MenuColors,
    Multiselect,
    Skill,
  },
  data() {
    return {
      isLoading: true,
      listSkills: [],
      errors: [],
      options: [],
      skill: "",
    };
  },
  watch: {
    skill() {
      this.addSkill();
    },
  },
  mounted() {
    init_template();
    this.isLoading = false;
    this.getData();
  },
  methods: {
    menuOpen,
    menuClose,
    async fetchSkill(query) {
      let queryData = [];
      await axios
        .get("/api/v1/employer/skill/?search=" + query)
        .then((response) => {
          console.log(response.data);
          queryData = response.data;
          this.options = response.data;
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error));
          }
        });
      return queryData.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    },
    async addSkill() {
      var formData = {
        id: this.skill,
      };

      await axios
        .post("/api/v1/job-seeker/profile/?field=skill", formData)
        .then((response) => {
          console.log("response add skill : ", response);
          this.getData();
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error));
          }
        });
    },
    getData() {
      axios
        .get("/api/v1/job-seeker/profile/?field=skills")
        .then((response) => {
          this.listSkills = response.data;
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background: #f1f1f1;
}

h3 {
  margin-top: 0;
}

.example pre {
  background: #1f2937;
  padding: 18px 20px;
  overflow-x: scroll;
  font-size: 0.85em;
}

.output {
  font-family: Courier, Courier New, Lucida Console, Monaco, Consolas;
  background: #000000;
  color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
}
.multiselect.multiselect-search {
  margin: 5px 0px !important;
}
.multiselect-tag.is-user {
  padding: 5px 8px;
  border-radius: 22px;
  background: #35495e;
  margin: 3px 3px 8px;
}

.multiselect-tag.is-user img {
  width: 18px;
  border-radius: 50%;
  height: 18px;
  margin-right: 8px;
  border: 2px solid #ffffffbf;
}

.multiselect-tag.is-user i:before {
  color: #ffffff;
  border-radius: 50%;
}

.user-image {
  margin: 0 6px 0 0;
  border-radius: 50%;
  height: 22px;
}

.character-option-icon {
  margin: 0 6px 0 0;
  height: 22px;
}

.character-label-icon {
  margin: 0 6px 0 0;
  height: 26px;
}

.toolbar {
  margin-right: 5px;

  button {
    cursor: pointer;
  }
}

[class*="language-"] {
  code,
  pre {
    color: #f8f8f2;
    background: none;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    font-size: 14px;
    line-height: 1.75;
    border-radius: 8px;
  }

  /* Code blocks */
  pre {
    overflow: auto;
    padding: 1.5rem;
  }

  /* Inline code */
  :not(pre) > code {
    padding: 0.1em;
    border-radius: 0.3em;
    white-space: normal;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #6272a4;
  }

  .token.punctuation {
    color: #f8f8f2;
  }

  .namespace {
    opacity: 0.7;
  }

  .token.property,
  .token.tag,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: #46ffe2;
  }

  .token.boolean,
  .token.number {
    color: #bd93f9;
  }

  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin {
    color: #50fa7b;
  }

  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string,
  .token.variable {
    color: #f8f8f2;
  }

  .token.atrule,
  .token.attr-value,
  .token.function,
  .token.class-name {
    color: #f1fa8c;
  }

  .keyword-highlight {
    padding: 0 2px;
    position: relative;

    &::after {
      content: "";
      background: currentColor;
      position: absolute;
      opacity: 0.25;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }
  }

  .token.keyword {
    color: #21d8ff;
  }

  .token.regex,
  .token.important {
    color: #ffb86c;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }

  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }
}
</style>
